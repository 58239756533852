
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonListHeader,
  IonGrid,
  IonRow,
  IonCol,

  onIonViewDidEnter
 } from '@ionic/vue';

import { checkmark } from 'ionicons/icons';

import { defineComponent } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { ApiError, isApiError, BudgetGroup } from '@/lib/api-endpoints';
import MInput from '@/components/MInput.vue';
import { useStore } from '@/store';
import * as yup from 'yup';
import t from '@/translation';
import { useField, useForm } from 'vee-validate';
import budgetIcons from '@/lib/budget_icons';


export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    IonListHeader,
    IonGrid,
    IonRow,
    IonCol,
    //IonItem,
    //IonLabel,
    //IonDatetime,
    //IonSelectOption,


    MInput,
    //MSelect,
    //MPassword,
  },
  setup(_props){

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const categorySchema = yup.object({
      name: yup.string().required().label(t("Name")),
      icon: yup.number().required().label(t("Icon")),
    });

    // Create a form context with the validation schema
    const { values, validate, resetForm } = useForm({
      validationSchema: categorySchema,
    });

    // create a field for the icon because it does not use a prebuilt component
    const { errorMessage: iconErrorMessage, value: iconIdx} = useField ("icon");

    // Load up the latest user details when entering this page
    onIonViewDidEnter(async () => {
      resetForm();
      const id = parseInt(route.params.budgetCategoryId as string);
      if (id){
        const group = store.state.budgetGroups.find( element => element.id == id );
        // populate the form with the details of the category to be edited.
        if (group){
          values.name = group.name;
          values.icon = group.icon;
        }

      }
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){

        let budgetGroup: ApiError | BudgetGroup;
        if (route.params.budgetCategoryId){
          const args = store.state.budgetGroups.find( element => element.id == parseInt(route.params.budgetCategoryId as string) );
          args!.name = values.name!;
          args!.icon = values.icon!;
          budgetGroup = await store.dispatch('updateBudgetGroup', args);
        } else {
          budgetGroup = await store.dispatch('createBudgetGroup', values);
        }

        if (! isApiError(budgetGroup)){
          router.go(-1); // go back to the last page.
          window.setTimeout(resetForm, 2000); // delayed so that validation errors are not seen before the page transistions.
        }
      }
    }

    return {
      onSubmitForm,
      iconIdx,
      iconErrorMessage,
      checkmark,
      budgetIcons,
      route,

    }
  }
});
